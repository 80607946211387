export const useLoader = definePiniaStore("loader", () => {

    const loading = ref<boolean>(false);
    const startLoading = () => {
        loading.value = true;
    };
    const stopLoading = () => {
        loading.value = false;
    };
    return {
        loading,
        startLoading,
        stopLoading
    };

});