import type { ICredential } from "@/types/credential-type";
import type { IPassword, IProfile } from "@/types/profile-type";
import type { ILoginResponse } from "@/types/responses/ILoginResponse";
import type { IRegisterForm } from "@/types/user-type";
import { defineStore, storeToRefs } from 'pinia';
import { toast } from "vue3-toastify";
import { useWishlistStore } from "./useWishlistStore";
import { useCartStore } from "./useCartStore";
import { useCheckoutStore } from "./useCheckoutStore";
import { useT } from "../helpers/useT";

export const useAuthStore = defineStore("auth", () => {
  const localePath = useLocalePath();
  // set cookies
  let user = ref();
  let token = useCookie("token", { default: () => "" });
  // set computed
  const isAuth = computed(() => {
    return token.value ? true : false;
  });
  const splittedName = computed(() => {
    return user.value?.name.split(" ") ?? ["", ""];
  })
  // set actions
  const { getCheckoutData } = useCheckoutStore();
  const { cartID } = storeToRefs(useCartStore());
  const signIn = async (credential: ICredential) => {
    const { getWishlistItems } = useWishlistStore();
    await fetch("/login", {
      method: "POST",
      body: JSON.stringify(credential),
    })
      .then(async (res: ILoginResponse | Response) => {
        user.value = res.data.user;
        token.value = res.data.token;
        toast.success(useT('welcome', { user: res.data.user.name }))
        let promiseArr = [
          getWishlistItems(token.value)
        ];
        if (res.data.user.cart) {
          promiseArr.push(getCheckoutData(res.data.user.cart.id))
          cartID.value = res.data.user.cart?.id ?? "";
        }

        //get cart and set it to cart store
        await Promise.all(promiseArr).then(() => {
          const route = useRoute();
          console.log(route.name);
          if (route.name != 'checkout') {

            navigateTo(localePath("/"));
          }

        });

      })
      .catch((err) => {
        console.log(err);
      });
  };
  const signOut = () => {
    user.value = undefined;
    token.value = undefined;
    cartID.value = "";
    navigateTo(localePath("/login"));
  };
  const signUp = async (registerData: IRegisterForm) => {
    let formattedRegisterData = {
      ...registerData,
      password_confirmation: registerData.confirmPassword,
      first_name: registerData.firstName,
      last_name: registerData.lastName,
    }
    return await fetch("/register", {
      method: "POST",
      body: JSON.stringify(formattedRegisterData),
    })
      .then((res) => {
        if (res.success) {
          toast.info(useT("Account created successfully"));
          user.value = res.data.user;
          token.value = res.data.token;
          navigateTo(localePath("/"));
        }
        return false;
      })
      .catch((err) => {
        // toast.error(err.data.message);
        return err.data;
      });
  };
  const getProfile = async (retrievedToken: string = '') => {
    let userToken = retrievedToken;
    if (!userToken.length) {
      userToken = token.value;
    }
    if (!userToken) return;
    await fetch("/profile", {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((res) => {
        user.value = res.data.user;

      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateProfile = (profile: IProfile) => {
    let formattedProfile = {
      ...profile,
      first_name: profile.firstName,
      last_name: profile.lastName,
    }
    fetch("/profile", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
      body: JSON.stringify(formattedProfile),
    }).then((res) => {
      if (res.success) {
        user.value = res.data;
        toast.success(useT("Profile updated successfully"));
      }
    })
  };
  const updatePassword = (passwordData: IPassword) => {

    fetch("/update-password", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
      body: JSON.stringify(passwordData),
    }).then((res) => {
      if (res.success) {
        toast.success(useT("Password updated successfully"));
        navigateTo(localePath("/"));
      }
    })
  };
  const uploadProfileImage = async (file: File) => {

    let formData = new FormData();
    formData.append("files", file);
    formData.append("collection", "profile");
    formData.append("source", "user");
    await fetch("/media/upload", {
      method: "POST",
      body: formData,

    }).then(async (res) => {
      if (res.success) {
        let profileData = {
          firstName: splittedName.value[0],
          lastName: splittedName.value[1],
          email: user.value?.email,
          phone: user.value?.phone,
          files_ids: [res.data[0].id],
        };
        await updateProfile(profileData);
      }
    }
    );
  };
  // return store data
  return {
    user,
    token,
    isAuth,
    splittedName,
    signIn,
    signOut,
    getProfile,
    updateProfile,
    signUp,
    updatePassword,
    uploadProfileImage
  };
});
