import { useAuthStore } from "@/pinia/useAuthStore";
import { useCurrencyStore } from "@/pinia/useCurrencyStore";
import { useLoader } from "@/pinia/useLoader";
import { useUtilityStore } from "@/pinia/useUtilityStore";
import { ofetch } from "ofetch";
import { storeToRefs } from "pinia";

export default defineNuxtPlugin((_nuxtApp) => {
  const route = useRoute();
  const tokenRoutes = ["/login", "/register"];
  const { currentCurrency } = storeToRefs(useCurrencyStore());
  const { signOut } = useAuthStore();
  globalThis.fetch = $fetch.create({
    baseURL: useRuntimeConfig().public.BASE_URL,
    // cache: "force-cache",
    async onRequest({ options, request }) {
      const locale = _nuxtApp.$i18n.locale;
      const accessToken = useCookie("token").value;
      // const { currentLanguage } = useUtilityStore();

      let headers = {
        "Accept-Language": locale.value, //ar ,en
        Currency: currentCurrency.value?.code ?? "", //code
        Accept: "application/json",
      };
      let query = {
        lang: _nuxtApp.$i18n.locale.value,
        currency: currentCurrency.value?.code,
      };
      options.query = {
        ...query,
        ...options.query,
      };
      let authorizationHeaders = {};

      if (accessToken) {
        authorizationHeaders = {
          Authorization:
            !tokenRoutes.includes(route.path) && `Bearer ${accessToken}`,
        };
      }
      options.headers = {
        ...options.headers,
        ...authorizationHeaders,
        ...headers,
      };
    },
    async onResponse(response) {
      if (response.response.status == 401) {
        signOut();
      }
    },
  });
});
