import { defineStore } from 'pinia';
import { toast } from "vue3-toastify";
import type { CartProduct, NewCartItem, CartItem, UpdateCartItem, Product } from "@/types/cart-type";
import { useAuthStore } from "./useAuthStore";

import type { ItemsEntity } from "@/types/checkout-type";
import { useCheckoutStore } from "./useCheckoutStore";
import { useT } from '../helpers/useT';

export const useCartStore = defineStore("cart_product", () => {
  // const { t } = useI18n();
  const { isAuth } = useAuthStore();
  const route = useRoute();
  let cart_products = ref<CartItem[]>([]);
  let orderQuantity = ref<number>(1);
  let cartOffcanvas = ref<boolean>(false);

  const cartID = useCookie<string>("cart_id", {
    default: () => { return "" }
  });

  const { getCheckoutData } = useCheckoutStore();
  const cartCount = computed(() => {
    return cart_products.value.length;
  });

  const currentQuantity = ref<number>(1);
  // add_cart_product
  const addCartProduct = async (payload: CartProduct) => {
    const existedItem = cart_products.value.find((i) => i.product_variation_id === payload.id);
    if (!existedItem) {

      await createCartItem({
        cart_id: isAuth ? "" : cartID.value,
        product_id: payload.product.id,
        product_variation_id: payload.variation.id,
        quantity: payload.quantity
      }, payload.product.name);
      return;
    }
    let product = cartProduct(existedItem);
    let totalQuantity = existedItem.quantity + payload.quantity;
    if (totalQuantity > product.current_stock) {
      toast.error(useT('no_available_quantity'), { title: product.name });
      return;
    }
    if (totalQuantity > product.max_purchase_qty) {
      toast.error(
        useT('you_cannot_add_more_than', { title: product.name, quantity: product.max_purchase_qty }));
      return;
    }
    await updateCartItem({
      cart_id: cartID.value,
      item_id: existedItem.id,
      quantity: totalQuantity
    }, payload.product.name);


  };

  // remover_cart_products
  const removeCartProduct = async (payload: CartItem) => {
    let title = payload.product?.name ?? payload.product_variation?.product.name;



    await removeCartItem(payload.id, title);
  };


  // clear cart
  const clear_cart = async () => {

    cart_products.value = [];
    localStorage.setItem("cart_products", JSON.stringify(cart_products.value));
    if (cartID.value) {
      await fetch(`/carts/${cartID.value}/clear`, {
        method: "DELETE"
      })
        .then((res) => {
          getCheckoutData();
        });
    }
  };
  const incrementQuantity = (current_stock: number, max_purchase_qty: number, cartItem: ItemsEntity | undefined = undefined) => {
    let modifiedQuantity = currentQuantity.value;
    if (cartItem) {
      modifiedQuantity = cartItem.quantity;
    }
    if (modifiedQuantity + 1 > current_stock) {
      toast.error(useT('you_cannot_add_more_than', { title: "", quantity: max_purchase_qty }));
      return;
    }
    if (modifiedQuantity + 1 > max_purchase_qty) {
      toast.error(useT("You can't add more than the maximum purchase quantity"));
      return;
    }
    if (cartItem) {
      cartItem.quantity++;
      updateCartItem({
        cart_id: cartID.value,
        item_id: cartItem.id,
        quantity: cartItem.quantity
      }, "")

    } else {
      currentQuantity.value++;
    }
  };
  const cartProduct = (item: CartItem): Product => {
    if (item.product) return item.product;
    return item.product_variation?.product;
  };
  const decrementQuantity = (min_purchase_qty: number, cartItem: ItemsEntity | undefined = undefined) => {
    let modifiedQuantity = currentQuantity.value;
    if (cartItem) {
      modifiedQuantity = cartItem.quantity;
    }
    if (modifiedQuantity <= 1) return;
    if (modifiedQuantity - 1 < min_purchase_qty) {
      toast.error("You can't add less than the minimum purchase quantity");
      return;
    }
    if (cartItem) {
      cartItem.quantity--;
      updateCartItem({
        cart_id: cartID.value,
        item_id: cartItem.id,
        quantity: cartItem.quantity
      }, "")
    } else {
      currentQuantity.value--;
    }
  };

  const createCartItem = async (newCartItem: NewCartItem, productName: string) => {
    await fetch(`/carts/add`, {
      method: "POST",
      body: JSON.stringify(newCartItem)
    })
      .then((res) => {
        if (cartID.value === "") {
          cartID.value = res.data.id
        }
        toast.success(
          useT('added_to_cart', { title: productName })
        );
        getCheckoutData();
      })
  }
  const removeCartItem = async (itemID: number, title: string) => {
    await fetch(`/carts/${cartID.value}/items/${itemID}`, {
      method: "DELETE",
    }).then((res) => {
      toast.error(useT('removed_from_cart', { title }));
      getCheckoutData();
    })
  }
  const updateCartItem = async (payload: UpdateCartItem, productName: string) => {
    await fetch(`carts/edit-item`, {
      method: "POST",
      body: JSON.stringify(payload)
    }).then((res) => {
      if (productName.length)
        toast.success(
          useT('added_to_cart', { title: productName })
        );
      getCheckoutData();
    });
  }
  // totalPriceQuantity
  const totalPriceQuantity = computed(() => {

    return cart_products.value.reduce(
      (cartTotal, cartItem) => {
        let price = 0;
        if (cartItem.product)
          price = cartItem.product.price;
        else
          price = cartItem.product_variation?.price || 0;
        const itemTotal = price * cartItem.quantity;
        cartTotal.quantity += cartItem.quantity;
        cartTotal.total += itemTotal;

        return cartTotal;
      },
      {
        total: 0,
        quantity: 0,
      }
    );
  });

  //handle cartOffcanvas
  const handleCartOffcanvas = () => {
    cartOffcanvas.value = !cartOffcanvas.value
  }
  // when router change than order quantity will be 1
  watch(() => route.path, () => {
    orderQuantity.value = 1
  })
  return {
    addCartProduct,
    removeCartProduct,
    clear_cart,
    handleCartOffcanvas,
    incrementQuantity,
    decrementQuantity,
    cartProduct,
    cart_products,
    totalPriceQuantity,
    cartOffcanvas,
    orderQuantity,
    cartCount,
    cartID,
    currentQuantity
  };
});
