import { default as index2vSYbEFvq9Meta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/index.vue?macro=true";
import { default as _404ts62RTltsRMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/404.vue?macro=true";
import { default as about6UYgIkrUMgMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/about.vue?macro=true";
import { default as blog_45details_452f2Fjw3xxLHMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/blog-details-2.vue?macro=true";
import { default as _91id_93qRKzDKtV7uMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/blog-details/[id].vue?macro=true";
import { default as indexTumaj0j8ocMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/blog-details/index.vue?macro=true";
import { default as blog_45gridapp99bLCPQMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/blog-grid.vue?macro=true";
import { default as blog_45listMdCs7ZQLKvMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/blog-list.vue?macro=true";
import { default as blogDYVTskRZYRMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/blog.vue?macro=true";
import { default as _91slug_93tTTFghLjpGMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/brand/[slug].vue?macro=true";
import { default as cartVwqgRYvSpwMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/cart.vue?macro=true";
import { default as _91slug_93xH70bA3CJ4Meta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/category/[slug].vue?macro=true";
import { default as checkoutvgpDrQwM1DMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/checkout.vue?macro=true";
import { default as comparemyorgF4ra9Meta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/compare.vue?macro=true";
import { default as contactLRShhu5v4aMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/contact.vue?macro=true";
import { default as couponsyI3eldBYD8Meta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/coupons.vue?macro=true";
import { default as forgot0DknUUtlZRMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/forgot.vue?macro=true";
import { default as home_452MbGS4OF2jkMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/home-2.vue?macro=true";
import { default as home_453IRv9uqp99YMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/home-3.vue?macro=true";
import { default as home_454N0wT7nDgf4Meta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/home-4.vue?macro=true";
import { default as logindGBvo6FuhQMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/login.vue?macro=true";
import { default as order_45confirmation3JdAS16fetMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/order-confirmation.vue?macro=true";
import { default as _91id_93YRxgaOhZfqMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/order/[id].vue?macro=true";
import { default as orderwlWmeNeza0Meta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/order.vue?macro=true";
import { default as _91slug_93MlDtnfnBDLMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/page/[slug].vue?macro=true";
import { default as product_45details_45countdown4pC6CcvIVzMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/product-details-countdown.vue?macro=true";
import { default as product_45details_45gallerypHyofXmjVTMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/product-details-gallery.vue?macro=true";
import { default as product_45details_45listUfn1lh7JFAMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/product-details-list.vue?macro=true";
import { default as product_45details_45sliderkoYaRC36zUMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/product-details-slider.vue?macro=true";
import { default as product_45details_45swatchesCagabmAZzgMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/product-details-swatches.vue?macro=true";
import { default as product_45details_45video7F75qG6ZxbMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/product-details-video.vue?macro=true";
import { default as _91id_93V1M9hGyGStMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/product-details/[id].vue?macro=true";
import { default as indexEe0ypV5nHbMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/product-details/index.vue?macro=true";
import { default as profileG17WF4fWGAMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/profile.vue?macro=true";
import { default as registerosgtIfcjvjMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/register.vue?macro=true";
import { default as searchWg07suc5FZMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/search.vue?macro=true";
import { default as shop_451600XvejGjCvbsMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/shop-1600.vue?macro=true";
import { default as shop_45categoriesIAOg2OIQEyMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/shop-categories.vue?macro=true";
import { default as shop_45filter_45dropdowniXbKl8kC7wMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/shop-filter-dropdown.vue?macro=true";
import { default as shop_45filter_45offcanvasrI9sZ5IWOuMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/shop-filter-offcanvas.vue?macro=true";
import { default as shop_45full_45widthSZnEdAdECUMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/shop-full-width.vue?macro=true";
import { default as shop_45list91yTUOitPQMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/shop-list.vue?macro=true";
import { default as shop_45load_45more3ZeJ9mkbitMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/shop-load-more.vue?macro=true";
import { default as shop_45no_45sidebarkdxCYx1BgPMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/shop-no-sidebar.vue?macro=true";
import { default as shop_45right_45sidebarSDRHJc2hkGMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/shop-right-sidebar.vue?macro=true";
import { default as shopZlsy0s5g7OMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/shop.vue?macro=true";
import { default as success_45login8SyYaxG1nVMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/success-login.vue?macro=true";
import { default as wishlistvwRdEjrh6AMeta } from "/home/urusglobal/swift-ecommerce.urusglobal.com/pages/wishlist.vue?macro=true";
export default [
  {
    name: index2vSYbEFvq9Meta?.name ?? "index",
    path: index2vSYbEFvq9Meta?.path ?? "/",
    meta: index2vSYbEFvq9Meta || {},
    alias: index2vSYbEFvq9Meta?.alias || [],
    redirect: index2vSYbEFvq9Meta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _404ts62RTltsRMeta?.name ?? "404___en",
    path: _404ts62RTltsRMeta?.path ?? "/en/404",
    meta: _404ts62RTltsRMeta || {},
    alias: _404ts62RTltsRMeta?.alias || [],
    redirect: _404ts62RTltsRMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _404ts62RTltsRMeta?.name ?? "404___ar",
    path: _404ts62RTltsRMeta?.path ?? "/ar/404",
    meta: _404ts62RTltsRMeta || {},
    alias: _404ts62RTltsRMeta?.alias || [],
    redirect: _404ts62RTltsRMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/404.vue").then(m => m.default || m)
  },
  {
    name: about6UYgIkrUMgMeta?.name ?? "about___en",
    path: about6UYgIkrUMgMeta?.path ?? "/en/about",
    meta: about6UYgIkrUMgMeta || {},
    alias: about6UYgIkrUMgMeta?.alias || [],
    redirect: about6UYgIkrUMgMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/about.vue").then(m => m.default || m)
  },
  {
    name: about6UYgIkrUMgMeta?.name ?? "about___ar",
    path: about6UYgIkrUMgMeta?.path ?? "/ar/about",
    meta: about6UYgIkrUMgMeta || {},
    alias: about6UYgIkrUMgMeta?.alias || [],
    redirect: about6UYgIkrUMgMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/about.vue").then(m => m.default || m)
  },
  {
    name: blog_45details_452f2Fjw3xxLHMeta?.name ?? "blog-details-2___en",
    path: blog_45details_452f2Fjw3xxLHMeta?.path ?? "/en/blog-details-2",
    meta: blog_45details_452f2Fjw3xxLHMeta || {},
    alias: blog_45details_452f2Fjw3xxLHMeta?.alias || [],
    redirect: blog_45details_452f2Fjw3xxLHMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/blog-details-2.vue").then(m => m.default || m)
  },
  {
    name: blog_45details_452f2Fjw3xxLHMeta?.name ?? "blog-details-2___ar",
    path: blog_45details_452f2Fjw3xxLHMeta?.path ?? "/ar/blog-details-2",
    meta: blog_45details_452f2Fjw3xxLHMeta || {},
    alias: blog_45details_452f2Fjw3xxLHMeta?.alias || [],
    redirect: blog_45details_452f2Fjw3xxLHMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/blog-details-2.vue").then(m => m.default || m)
  },
  {
    name: _91id_93qRKzDKtV7uMeta?.name ?? "blog-details-id___en",
    path: _91id_93qRKzDKtV7uMeta?.path ?? "/en/blog-details/:id()",
    meta: _91id_93qRKzDKtV7uMeta || {},
    alias: _91id_93qRKzDKtV7uMeta?.alias || [],
    redirect: _91id_93qRKzDKtV7uMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/blog-details/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93qRKzDKtV7uMeta?.name ?? "blog-details-id___ar",
    path: _91id_93qRKzDKtV7uMeta?.path ?? "/ar/blog-details/:id()",
    meta: _91id_93qRKzDKtV7uMeta || {},
    alias: _91id_93qRKzDKtV7uMeta?.alias || [],
    redirect: _91id_93qRKzDKtV7uMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/blog-details/[id].vue").then(m => m.default || m)
  },
  {
    name: indexTumaj0j8ocMeta?.name ?? "blog-details___en",
    path: indexTumaj0j8ocMeta?.path ?? "/en/blog-details",
    meta: indexTumaj0j8ocMeta || {},
    alias: indexTumaj0j8ocMeta?.alias || [],
    redirect: indexTumaj0j8ocMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/blog-details/index.vue").then(m => m.default || m)
  },
  {
    name: indexTumaj0j8ocMeta?.name ?? "blog-details___ar",
    path: indexTumaj0j8ocMeta?.path ?? "/ar/blog-details",
    meta: indexTumaj0j8ocMeta || {},
    alias: indexTumaj0j8ocMeta?.alias || [],
    redirect: indexTumaj0j8ocMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/blog-details/index.vue").then(m => m.default || m)
  },
  {
    name: blog_45gridapp99bLCPQMeta?.name ?? "blog-grid___en",
    path: blog_45gridapp99bLCPQMeta?.path ?? "/en/blog-grid",
    meta: blog_45gridapp99bLCPQMeta || {},
    alias: blog_45gridapp99bLCPQMeta?.alias || [],
    redirect: blog_45gridapp99bLCPQMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/blog-grid.vue").then(m => m.default || m)
  },
  {
    name: blog_45gridapp99bLCPQMeta?.name ?? "blog-grid___ar",
    path: blog_45gridapp99bLCPQMeta?.path ?? "/ar/blog-grid",
    meta: blog_45gridapp99bLCPQMeta || {},
    alias: blog_45gridapp99bLCPQMeta?.alias || [],
    redirect: blog_45gridapp99bLCPQMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/blog-grid.vue").then(m => m.default || m)
  },
  {
    name: blog_45listMdCs7ZQLKvMeta?.name ?? "blog-list___en",
    path: blog_45listMdCs7ZQLKvMeta?.path ?? "/en/blog-list",
    meta: blog_45listMdCs7ZQLKvMeta || {},
    alias: blog_45listMdCs7ZQLKvMeta?.alias || [],
    redirect: blog_45listMdCs7ZQLKvMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/blog-list.vue").then(m => m.default || m)
  },
  {
    name: blog_45listMdCs7ZQLKvMeta?.name ?? "blog-list___ar",
    path: blog_45listMdCs7ZQLKvMeta?.path ?? "/ar/blog-list",
    meta: blog_45listMdCs7ZQLKvMeta || {},
    alias: blog_45listMdCs7ZQLKvMeta?.alias || [],
    redirect: blog_45listMdCs7ZQLKvMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/blog-list.vue").then(m => m.default || m)
  },
  {
    name: blogDYVTskRZYRMeta?.name ?? "blog___en",
    path: blogDYVTskRZYRMeta?.path ?? "/en/blog",
    meta: blogDYVTskRZYRMeta || {},
    alias: blogDYVTskRZYRMeta?.alias || [],
    redirect: blogDYVTskRZYRMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/blog.vue").then(m => m.default || m)
  },
  {
    name: blogDYVTskRZYRMeta?.name ?? "blog___ar",
    path: blogDYVTskRZYRMeta?.path ?? "/ar/blog",
    meta: blogDYVTskRZYRMeta || {},
    alias: blogDYVTskRZYRMeta?.alias || [],
    redirect: blogDYVTskRZYRMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/blog.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tTTFghLjpGMeta?.name ?? "brand-slug___en",
    path: _91slug_93tTTFghLjpGMeta?.path ?? "/en/brand/:slug()",
    meta: _91slug_93tTTFghLjpGMeta || {},
    alias: _91slug_93tTTFghLjpGMeta?.alias || [],
    redirect: _91slug_93tTTFghLjpGMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/brand/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tTTFghLjpGMeta?.name ?? "brand-slug___ar",
    path: _91slug_93tTTFghLjpGMeta?.path ?? "/ar/brand/:slug()",
    meta: _91slug_93tTTFghLjpGMeta || {},
    alias: _91slug_93tTTFghLjpGMeta?.alias || [],
    redirect: _91slug_93tTTFghLjpGMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/brand/[slug].vue").then(m => m.default || m)
  },
  {
    name: cartVwqgRYvSpwMeta?.name ?? "cart___en",
    path: cartVwqgRYvSpwMeta?.path ?? "/en/cart",
    meta: cartVwqgRYvSpwMeta || {},
    alias: cartVwqgRYvSpwMeta?.alias || [],
    redirect: cartVwqgRYvSpwMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: cartVwqgRYvSpwMeta?.name ?? "cart___ar",
    path: cartVwqgRYvSpwMeta?.path ?? "/ar/cart",
    meta: cartVwqgRYvSpwMeta || {},
    alias: cartVwqgRYvSpwMeta?.alias || [],
    redirect: cartVwqgRYvSpwMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xH70bA3CJ4Meta?.name ?? "category-slug___en",
    path: _91slug_93xH70bA3CJ4Meta?.path ?? "/en/category/:slug()",
    meta: _91slug_93xH70bA3CJ4Meta || {},
    alias: _91slug_93xH70bA3CJ4Meta?.alias || [],
    redirect: _91slug_93xH70bA3CJ4Meta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xH70bA3CJ4Meta?.name ?? "category-slug___ar",
    path: _91slug_93xH70bA3CJ4Meta?.path ?? "/ar/category/:slug()",
    meta: _91slug_93xH70bA3CJ4Meta || {},
    alias: _91slug_93xH70bA3CJ4Meta?.alias || [],
    redirect: _91slug_93xH70bA3CJ4Meta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: checkoutvgpDrQwM1DMeta?.name ?? "checkout___en",
    path: checkoutvgpDrQwM1DMeta?.path ?? "/en/checkout",
    meta: checkoutvgpDrQwM1DMeta || {},
    alias: checkoutvgpDrQwM1DMeta?.alias || [],
    redirect: checkoutvgpDrQwM1DMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkoutvgpDrQwM1DMeta?.name ?? "checkout___ar",
    path: checkoutvgpDrQwM1DMeta?.path ?? "/ar/checkout",
    meta: checkoutvgpDrQwM1DMeta || {},
    alias: checkoutvgpDrQwM1DMeta?.alias || [],
    redirect: checkoutvgpDrQwM1DMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: comparemyorgF4ra9Meta?.name ?? "compare___en",
    path: comparemyorgF4ra9Meta?.path ?? "/en/compare",
    meta: comparemyorgF4ra9Meta || {},
    alias: comparemyorgF4ra9Meta?.alias || [],
    redirect: comparemyorgF4ra9Meta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/compare.vue").then(m => m.default || m)
  },
  {
    name: comparemyorgF4ra9Meta?.name ?? "compare___ar",
    path: comparemyorgF4ra9Meta?.path ?? "/ar/compare",
    meta: comparemyorgF4ra9Meta || {},
    alias: comparemyorgF4ra9Meta?.alias || [],
    redirect: comparemyorgF4ra9Meta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/compare.vue").then(m => m.default || m)
  },
  {
    name: contactLRShhu5v4aMeta?.name ?? "contact___en",
    path: contactLRShhu5v4aMeta?.path ?? "/en/contact",
    meta: contactLRShhu5v4aMeta || {},
    alias: contactLRShhu5v4aMeta?.alias || [],
    redirect: contactLRShhu5v4aMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactLRShhu5v4aMeta?.name ?? "contact___ar",
    path: contactLRShhu5v4aMeta?.path ?? "/ar/contact",
    meta: contactLRShhu5v4aMeta || {},
    alias: contactLRShhu5v4aMeta?.alias || [],
    redirect: contactLRShhu5v4aMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: couponsyI3eldBYD8Meta?.name ?? "coupons___en",
    path: couponsyI3eldBYD8Meta?.path ?? "/en/coupons",
    meta: couponsyI3eldBYD8Meta || {},
    alias: couponsyI3eldBYD8Meta?.alias || [],
    redirect: couponsyI3eldBYD8Meta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/coupons.vue").then(m => m.default || m)
  },
  {
    name: couponsyI3eldBYD8Meta?.name ?? "coupons___ar",
    path: couponsyI3eldBYD8Meta?.path ?? "/ar/coupons",
    meta: couponsyI3eldBYD8Meta || {},
    alias: couponsyI3eldBYD8Meta?.alias || [],
    redirect: couponsyI3eldBYD8Meta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/coupons.vue").then(m => m.default || m)
  },
  {
    name: forgot0DknUUtlZRMeta?.name ?? "forgot___en",
    path: forgot0DknUUtlZRMeta?.path ?? "/en/forgot",
    meta: forgot0DknUUtlZRMeta || {},
    alias: forgot0DknUUtlZRMeta?.alias || [],
    redirect: forgot0DknUUtlZRMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/forgot.vue").then(m => m.default || m)
  },
  {
    name: forgot0DknUUtlZRMeta?.name ?? "forgot___ar",
    path: forgot0DknUUtlZRMeta?.path ?? "/ar/forgot",
    meta: forgot0DknUUtlZRMeta || {},
    alias: forgot0DknUUtlZRMeta?.alias || [],
    redirect: forgot0DknUUtlZRMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/forgot.vue").then(m => m.default || m)
  },
  {
    name: home_452MbGS4OF2jkMeta?.name ?? "home-2___en",
    path: home_452MbGS4OF2jkMeta?.path ?? "/en/home-2",
    meta: home_452MbGS4OF2jkMeta || {},
    alias: home_452MbGS4OF2jkMeta?.alias || [],
    redirect: home_452MbGS4OF2jkMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/home-2.vue").then(m => m.default || m)
  },
  {
    name: home_452MbGS4OF2jkMeta?.name ?? "home-2___ar",
    path: home_452MbGS4OF2jkMeta?.path ?? "/ar/home-2",
    meta: home_452MbGS4OF2jkMeta || {},
    alias: home_452MbGS4OF2jkMeta?.alias || [],
    redirect: home_452MbGS4OF2jkMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/home-2.vue").then(m => m.default || m)
  },
  {
    name: home_453IRv9uqp99YMeta?.name ?? "home-3___en",
    path: home_453IRv9uqp99YMeta?.path ?? "/en/home-3",
    meta: home_453IRv9uqp99YMeta || {},
    alias: home_453IRv9uqp99YMeta?.alias || [],
    redirect: home_453IRv9uqp99YMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/home-3.vue").then(m => m.default || m)
  },
  {
    name: home_453IRv9uqp99YMeta?.name ?? "home-3___ar",
    path: home_453IRv9uqp99YMeta?.path ?? "/ar/home-3",
    meta: home_453IRv9uqp99YMeta || {},
    alias: home_453IRv9uqp99YMeta?.alias || [],
    redirect: home_453IRv9uqp99YMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/home-3.vue").then(m => m.default || m)
  },
  {
    name: home_454N0wT7nDgf4Meta?.name ?? "home-4___en",
    path: home_454N0wT7nDgf4Meta?.path ?? "/en/home-4",
    meta: home_454N0wT7nDgf4Meta || {},
    alias: home_454N0wT7nDgf4Meta?.alias || [],
    redirect: home_454N0wT7nDgf4Meta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/home-4.vue").then(m => m.default || m)
  },
  {
    name: home_454N0wT7nDgf4Meta?.name ?? "home-4___ar",
    path: home_454N0wT7nDgf4Meta?.path ?? "/ar/home-4",
    meta: home_454N0wT7nDgf4Meta || {},
    alias: home_454N0wT7nDgf4Meta?.alias || [],
    redirect: home_454N0wT7nDgf4Meta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/home-4.vue").then(m => m.default || m)
  },
  {
    name: index2vSYbEFvq9Meta?.name ?? "index___en",
    path: index2vSYbEFvq9Meta?.path ?? "/en",
    meta: index2vSYbEFvq9Meta || {},
    alias: index2vSYbEFvq9Meta?.alias || [],
    redirect: index2vSYbEFvq9Meta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index2vSYbEFvq9Meta?.name ?? "index___ar",
    path: index2vSYbEFvq9Meta?.path ?? "/ar",
    meta: index2vSYbEFvq9Meta || {},
    alias: index2vSYbEFvq9Meta?.alias || [],
    redirect: index2vSYbEFvq9Meta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: logindGBvo6FuhQMeta?.name ?? "login___en",
    path: logindGBvo6FuhQMeta?.path ?? "/en/login",
    meta: logindGBvo6FuhQMeta || {},
    alias: logindGBvo6FuhQMeta?.alias || [],
    redirect: logindGBvo6FuhQMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logindGBvo6FuhQMeta?.name ?? "login___ar",
    path: logindGBvo6FuhQMeta?.path ?? "/ar/login",
    meta: logindGBvo6FuhQMeta || {},
    alias: logindGBvo6FuhQMeta?.alias || [],
    redirect: logindGBvo6FuhQMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/login.vue").then(m => m.default || m)
  },
  {
    name: order_45confirmation3JdAS16fetMeta?.name ?? "order-confirmation___en",
    path: order_45confirmation3JdAS16fetMeta?.path ?? "/en/order-confirmation",
    meta: order_45confirmation3JdAS16fetMeta || {},
    alias: order_45confirmation3JdAS16fetMeta?.alias || [],
    redirect: order_45confirmation3JdAS16fetMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/order-confirmation.vue").then(m => m.default || m)
  },
  {
    name: order_45confirmation3JdAS16fetMeta?.name ?? "order-confirmation___ar",
    path: order_45confirmation3JdAS16fetMeta?.path ?? "/ar/order-confirmation",
    meta: order_45confirmation3JdAS16fetMeta || {},
    alias: order_45confirmation3JdAS16fetMeta?.alias || [],
    redirect: order_45confirmation3JdAS16fetMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/order-confirmation.vue").then(m => m.default || m)
  },
  {
    name: orderwlWmeNeza0Meta?.name ?? "order___en",
    path: orderwlWmeNeza0Meta?.path ?? "/en/order",
    children: [
  {
    name: _91id_93YRxgaOhZfqMeta?.name ?? "order-id___en",
    path: _91id_93YRxgaOhZfqMeta?.path ?? ":id()",
    meta: _91id_93YRxgaOhZfqMeta || {},
    alias: _91id_93YRxgaOhZfqMeta?.alias || [],
    redirect: _91id_93YRxgaOhZfqMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/order/[id].vue").then(m => m.default || m)
  }
],
    meta: orderwlWmeNeza0Meta || {},
    alias: orderwlWmeNeza0Meta?.alias || [],
    redirect: orderwlWmeNeza0Meta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/order.vue").then(m => m.default || m)
  },
  {
    name: orderwlWmeNeza0Meta?.name ?? "order___ar",
    path: orderwlWmeNeza0Meta?.path ?? "/ar/order",
    children: [
  {
    name: _91id_93YRxgaOhZfqMeta?.name ?? "order-id___ar",
    path: _91id_93YRxgaOhZfqMeta?.path ?? ":id()",
    meta: _91id_93YRxgaOhZfqMeta || {},
    alias: _91id_93YRxgaOhZfqMeta?.alias || [],
    redirect: _91id_93YRxgaOhZfqMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/order/[id].vue").then(m => m.default || m)
  }
],
    meta: orderwlWmeNeza0Meta || {},
    alias: orderwlWmeNeza0Meta?.alias || [],
    redirect: orderwlWmeNeza0Meta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/order.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MlDtnfnBDLMeta?.name ?? "page-slug___en",
    path: _91slug_93MlDtnfnBDLMeta?.path ?? "/en/page/:slug()",
    meta: _91slug_93MlDtnfnBDLMeta || {},
    alias: _91slug_93MlDtnfnBDLMeta?.alias || [],
    redirect: _91slug_93MlDtnfnBDLMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/page/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MlDtnfnBDLMeta?.name ?? "page-slug___ar",
    path: _91slug_93MlDtnfnBDLMeta?.path ?? "/ar/page/:slug()",
    meta: _91slug_93MlDtnfnBDLMeta || {},
    alias: _91slug_93MlDtnfnBDLMeta?.alias || [],
    redirect: _91slug_93MlDtnfnBDLMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/page/[slug].vue").then(m => m.default || m)
  },
  {
    name: product_45details_45countdown4pC6CcvIVzMeta?.name ?? "product-details-countdown___en",
    path: product_45details_45countdown4pC6CcvIVzMeta?.path ?? "/en/product-details-countdown",
    meta: product_45details_45countdown4pC6CcvIVzMeta || {},
    alias: product_45details_45countdown4pC6CcvIVzMeta?.alias || [],
    redirect: product_45details_45countdown4pC6CcvIVzMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/product-details-countdown.vue").then(m => m.default || m)
  },
  {
    name: product_45details_45countdown4pC6CcvIVzMeta?.name ?? "product-details-countdown___ar",
    path: product_45details_45countdown4pC6CcvIVzMeta?.path ?? "/ar/product-details-countdown",
    meta: product_45details_45countdown4pC6CcvIVzMeta || {},
    alias: product_45details_45countdown4pC6CcvIVzMeta?.alias || [],
    redirect: product_45details_45countdown4pC6CcvIVzMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/product-details-countdown.vue").then(m => m.default || m)
  },
  {
    name: product_45details_45gallerypHyofXmjVTMeta?.name ?? "product-details-gallery___en",
    path: product_45details_45gallerypHyofXmjVTMeta?.path ?? "/en/product-details-gallery",
    meta: product_45details_45gallerypHyofXmjVTMeta || {},
    alias: product_45details_45gallerypHyofXmjVTMeta?.alias || [],
    redirect: product_45details_45gallerypHyofXmjVTMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/product-details-gallery.vue").then(m => m.default || m)
  },
  {
    name: product_45details_45gallerypHyofXmjVTMeta?.name ?? "product-details-gallery___ar",
    path: product_45details_45gallerypHyofXmjVTMeta?.path ?? "/ar/product-details-gallery",
    meta: product_45details_45gallerypHyofXmjVTMeta || {},
    alias: product_45details_45gallerypHyofXmjVTMeta?.alias || [],
    redirect: product_45details_45gallerypHyofXmjVTMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/product-details-gallery.vue").then(m => m.default || m)
  },
  {
    name: product_45details_45listUfn1lh7JFAMeta?.name ?? "product-details-list___en",
    path: product_45details_45listUfn1lh7JFAMeta?.path ?? "/en/product-details-list",
    meta: product_45details_45listUfn1lh7JFAMeta || {},
    alias: product_45details_45listUfn1lh7JFAMeta?.alias || [],
    redirect: product_45details_45listUfn1lh7JFAMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/product-details-list.vue").then(m => m.default || m)
  },
  {
    name: product_45details_45listUfn1lh7JFAMeta?.name ?? "product-details-list___ar",
    path: product_45details_45listUfn1lh7JFAMeta?.path ?? "/ar/product-details-list",
    meta: product_45details_45listUfn1lh7JFAMeta || {},
    alias: product_45details_45listUfn1lh7JFAMeta?.alias || [],
    redirect: product_45details_45listUfn1lh7JFAMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/product-details-list.vue").then(m => m.default || m)
  },
  {
    name: product_45details_45sliderkoYaRC36zUMeta?.name ?? "product-details-slider___en",
    path: product_45details_45sliderkoYaRC36zUMeta?.path ?? "/en/product-details-slider",
    meta: product_45details_45sliderkoYaRC36zUMeta || {},
    alias: product_45details_45sliderkoYaRC36zUMeta?.alias || [],
    redirect: product_45details_45sliderkoYaRC36zUMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/product-details-slider.vue").then(m => m.default || m)
  },
  {
    name: product_45details_45sliderkoYaRC36zUMeta?.name ?? "product-details-slider___ar",
    path: product_45details_45sliderkoYaRC36zUMeta?.path ?? "/ar/product-details-slider",
    meta: product_45details_45sliderkoYaRC36zUMeta || {},
    alias: product_45details_45sliderkoYaRC36zUMeta?.alias || [],
    redirect: product_45details_45sliderkoYaRC36zUMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/product-details-slider.vue").then(m => m.default || m)
  },
  {
    name: product_45details_45swatchesCagabmAZzgMeta?.name ?? "product-details-swatches___en",
    path: product_45details_45swatchesCagabmAZzgMeta?.path ?? "/en/product-details-swatches",
    meta: product_45details_45swatchesCagabmAZzgMeta || {},
    alias: product_45details_45swatchesCagabmAZzgMeta?.alias || [],
    redirect: product_45details_45swatchesCagabmAZzgMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/product-details-swatches.vue").then(m => m.default || m)
  },
  {
    name: product_45details_45swatchesCagabmAZzgMeta?.name ?? "product-details-swatches___ar",
    path: product_45details_45swatchesCagabmAZzgMeta?.path ?? "/ar/product-details-swatches",
    meta: product_45details_45swatchesCagabmAZzgMeta || {},
    alias: product_45details_45swatchesCagabmAZzgMeta?.alias || [],
    redirect: product_45details_45swatchesCagabmAZzgMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/product-details-swatches.vue").then(m => m.default || m)
  },
  {
    name: product_45details_45video7F75qG6ZxbMeta?.name ?? "product-details-video___en",
    path: product_45details_45video7F75qG6ZxbMeta?.path ?? "/en/product-details-video",
    meta: product_45details_45video7F75qG6ZxbMeta || {},
    alias: product_45details_45video7F75qG6ZxbMeta?.alias || [],
    redirect: product_45details_45video7F75qG6ZxbMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/product-details-video.vue").then(m => m.default || m)
  },
  {
    name: product_45details_45video7F75qG6ZxbMeta?.name ?? "product-details-video___ar",
    path: product_45details_45video7F75qG6ZxbMeta?.path ?? "/ar/product-details-video",
    meta: product_45details_45video7F75qG6ZxbMeta || {},
    alias: product_45details_45video7F75qG6ZxbMeta?.alias || [],
    redirect: product_45details_45video7F75qG6ZxbMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/product-details-video.vue").then(m => m.default || m)
  },
  {
    name: _91id_93V1M9hGyGStMeta?.name ?? "product-details-id___en",
    path: _91id_93V1M9hGyGStMeta?.path ?? "/en/product-details/:id()",
    meta: _91id_93V1M9hGyGStMeta || {},
    alias: _91id_93V1M9hGyGStMeta?.alias || [],
    redirect: _91id_93V1M9hGyGStMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/product-details/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93V1M9hGyGStMeta?.name ?? "product-details-id___ar",
    path: _91id_93V1M9hGyGStMeta?.path ?? "/ar/product-details/:id()",
    meta: _91id_93V1M9hGyGStMeta || {},
    alias: _91id_93V1M9hGyGStMeta?.alias || [],
    redirect: _91id_93V1M9hGyGStMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/product-details/[id].vue").then(m => m.default || m)
  },
  {
    name: indexEe0ypV5nHbMeta?.name ?? "product-details___en",
    path: indexEe0ypV5nHbMeta?.path ?? "/en/product-details",
    meta: indexEe0ypV5nHbMeta || {},
    alias: indexEe0ypV5nHbMeta?.alias || [],
    redirect: indexEe0ypV5nHbMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/product-details/index.vue").then(m => m.default || m)
  },
  {
    name: indexEe0ypV5nHbMeta?.name ?? "product-details___ar",
    path: indexEe0ypV5nHbMeta?.path ?? "/ar/product-details",
    meta: indexEe0ypV5nHbMeta || {},
    alias: indexEe0ypV5nHbMeta?.alias || [],
    redirect: indexEe0ypV5nHbMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/product-details/index.vue").then(m => m.default || m)
  },
  {
    name: profileG17WF4fWGAMeta?.name ?? "profile___en",
    path: profileG17WF4fWGAMeta?.path ?? "/en/profile",
    meta: profileG17WF4fWGAMeta || {},
    alias: profileG17WF4fWGAMeta?.alias || [],
    redirect: profileG17WF4fWGAMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profileG17WF4fWGAMeta?.name ?? "profile___ar",
    path: profileG17WF4fWGAMeta?.path ?? "/ar/profile",
    meta: profileG17WF4fWGAMeta || {},
    alias: profileG17WF4fWGAMeta?.alias || [],
    redirect: profileG17WF4fWGAMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: registerosgtIfcjvjMeta?.name ?? "register___en",
    path: registerosgtIfcjvjMeta?.path ?? "/en/register",
    meta: registerosgtIfcjvjMeta || {},
    alias: registerosgtIfcjvjMeta?.alias || [],
    redirect: registerosgtIfcjvjMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerosgtIfcjvjMeta?.name ?? "register___ar",
    path: registerosgtIfcjvjMeta?.path ?? "/ar/register",
    meta: registerosgtIfcjvjMeta || {},
    alias: registerosgtIfcjvjMeta?.alias || [],
    redirect: registerosgtIfcjvjMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/register.vue").then(m => m.default || m)
  },
  {
    name: searchWg07suc5FZMeta?.name ?? "search___en",
    path: searchWg07suc5FZMeta?.path ?? "/en/search",
    meta: searchWg07suc5FZMeta || {},
    alias: searchWg07suc5FZMeta?.alias || [],
    redirect: searchWg07suc5FZMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchWg07suc5FZMeta?.name ?? "search___ar",
    path: searchWg07suc5FZMeta?.path ?? "/ar/search",
    meta: searchWg07suc5FZMeta || {},
    alias: searchWg07suc5FZMeta?.alias || [],
    redirect: searchWg07suc5FZMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/search.vue").then(m => m.default || m)
  },
  {
    name: shop_451600XvejGjCvbsMeta?.name ?? "shop-1600___en",
    path: shop_451600XvejGjCvbsMeta?.path ?? "/en/shop-1600",
    meta: shop_451600XvejGjCvbsMeta || {},
    alias: shop_451600XvejGjCvbsMeta?.alias || [],
    redirect: shop_451600XvejGjCvbsMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/shop-1600.vue").then(m => m.default || m)
  },
  {
    name: shop_451600XvejGjCvbsMeta?.name ?? "shop-1600___ar",
    path: shop_451600XvejGjCvbsMeta?.path ?? "/ar/shop-1600",
    meta: shop_451600XvejGjCvbsMeta || {},
    alias: shop_451600XvejGjCvbsMeta?.alias || [],
    redirect: shop_451600XvejGjCvbsMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/shop-1600.vue").then(m => m.default || m)
  },
  {
    name: shop_45categoriesIAOg2OIQEyMeta?.name ?? "shop-categories___en",
    path: shop_45categoriesIAOg2OIQEyMeta?.path ?? "/en/shop-categories",
    meta: shop_45categoriesIAOg2OIQEyMeta || {},
    alias: shop_45categoriesIAOg2OIQEyMeta?.alias || [],
    redirect: shop_45categoriesIAOg2OIQEyMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/shop-categories.vue").then(m => m.default || m)
  },
  {
    name: shop_45categoriesIAOg2OIQEyMeta?.name ?? "shop-categories___ar",
    path: shop_45categoriesIAOg2OIQEyMeta?.path ?? "/ar/shop-categories",
    meta: shop_45categoriesIAOg2OIQEyMeta || {},
    alias: shop_45categoriesIAOg2OIQEyMeta?.alias || [],
    redirect: shop_45categoriesIAOg2OIQEyMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/shop-categories.vue").then(m => m.default || m)
  },
  {
    name: shop_45filter_45dropdowniXbKl8kC7wMeta?.name ?? "shop-filter-dropdown___en",
    path: shop_45filter_45dropdowniXbKl8kC7wMeta?.path ?? "/en/shop-filter-dropdown",
    meta: shop_45filter_45dropdowniXbKl8kC7wMeta || {},
    alias: shop_45filter_45dropdowniXbKl8kC7wMeta?.alias || [],
    redirect: shop_45filter_45dropdowniXbKl8kC7wMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/shop-filter-dropdown.vue").then(m => m.default || m)
  },
  {
    name: shop_45filter_45dropdowniXbKl8kC7wMeta?.name ?? "shop-filter-dropdown___ar",
    path: shop_45filter_45dropdowniXbKl8kC7wMeta?.path ?? "/ar/shop-filter-dropdown",
    meta: shop_45filter_45dropdowniXbKl8kC7wMeta || {},
    alias: shop_45filter_45dropdowniXbKl8kC7wMeta?.alias || [],
    redirect: shop_45filter_45dropdowniXbKl8kC7wMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/shop-filter-dropdown.vue").then(m => m.default || m)
  },
  {
    name: shop_45filter_45offcanvasrI9sZ5IWOuMeta?.name ?? "shop-filter-offcanvas___en",
    path: shop_45filter_45offcanvasrI9sZ5IWOuMeta?.path ?? "/en/shop-filter-offcanvas",
    meta: shop_45filter_45offcanvasrI9sZ5IWOuMeta || {},
    alias: shop_45filter_45offcanvasrI9sZ5IWOuMeta?.alias || [],
    redirect: shop_45filter_45offcanvasrI9sZ5IWOuMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/shop-filter-offcanvas.vue").then(m => m.default || m)
  },
  {
    name: shop_45filter_45offcanvasrI9sZ5IWOuMeta?.name ?? "shop-filter-offcanvas___ar",
    path: shop_45filter_45offcanvasrI9sZ5IWOuMeta?.path ?? "/ar/shop-filter-offcanvas",
    meta: shop_45filter_45offcanvasrI9sZ5IWOuMeta || {},
    alias: shop_45filter_45offcanvasrI9sZ5IWOuMeta?.alias || [],
    redirect: shop_45filter_45offcanvasrI9sZ5IWOuMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/shop-filter-offcanvas.vue").then(m => m.default || m)
  },
  {
    name: shop_45full_45widthSZnEdAdECUMeta?.name ?? "shop-full-width___en",
    path: shop_45full_45widthSZnEdAdECUMeta?.path ?? "/en/shop-full-width",
    meta: shop_45full_45widthSZnEdAdECUMeta || {},
    alias: shop_45full_45widthSZnEdAdECUMeta?.alias || [],
    redirect: shop_45full_45widthSZnEdAdECUMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/shop-full-width.vue").then(m => m.default || m)
  },
  {
    name: shop_45full_45widthSZnEdAdECUMeta?.name ?? "shop-full-width___ar",
    path: shop_45full_45widthSZnEdAdECUMeta?.path ?? "/ar/shop-full-width",
    meta: shop_45full_45widthSZnEdAdECUMeta || {},
    alias: shop_45full_45widthSZnEdAdECUMeta?.alias || [],
    redirect: shop_45full_45widthSZnEdAdECUMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/shop-full-width.vue").then(m => m.default || m)
  },
  {
    name: shop_45list91yTUOitPQMeta?.name ?? "shop-list___en",
    path: shop_45list91yTUOitPQMeta?.path ?? "/en/shop-list",
    meta: shop_45list91yTUOitPQMeta || {},
    alias: shop_45list91yTUOitPQMeta?.alias || [],
    redirect: shop_45list91yTUOitPQMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/shop-list.vue").then(m => m.default || m)
  },
  {
    name: shop_45list91yTUOitPQMeta?.name ?? "shop-list___ar",
    path: shop_45list91yTUOitPQMeta?.path ?? "/ar/shop-list",
    meta: shop_45list91yTUOitPQMeta || {},
    alias: shop_45list91yTUOitPQMeta?.alias || [],
    redirect: shop_45list91yTUOitPQMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/shop-list.vue").then(m => m.default || m)
  },
  {
    name: shop_45load_45more3ZeJ9mkbitMeta?.name ?? "shop-load-more___en",
    path: shop_45load_45more3ZeJ9mkbitMeta?.path ?? "/en/shop-load-more",
    meta: shop_45load_45more3ZeJ9mkbitMeta || {},
    alias: shop_45load_45more3ZeJ9mkbitMeta?.alias || [],
    redirect: shop_45load_45more3ZeJ9mkbitMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/shop-load-more.vue").then(m => m.default || m)
  },
  {
    name: shop_45load_45more3ZeJ9mkbitMeta?.name ?? "shop-load-more___ar",
    path: shop_45load_45more3ZeJ9mkbitMeta?.path ?? "/ar/shop-load-more",
    meta: shop_45load_45more3ZeJ9mkbitMeta || {},
    alias: shop_45load_45more3ZeJ9mkbitMeta?.alias || [],
    redirect: shop_45load_45more3ZeJ9mkbitMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/shop-load-more.vue").then(m => m.default || m)
  },
  {
    name: shop_45no_45sidebarkdxCYx1BgPMeta?.name ?? "shop-no-sidebar___en",
    path: shop_45no_45sidebarkdxCYx1BgPMeta?.path ?? "/en/shop-no-sidebar",
    meta: shop_45no_45sidebarkdxCYx1BgPMeta || {},
    alias: shop_45no_45sidebarkdxCYx1BgPMeta?.alias || [],
    redirect: shop_45no_45sidebarkdxCYx1BgPMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/shop-no-sidebar.vue").then(m => m.default || m)
  },
  {
    name: shop_45no_45sidebarkdxCYx1BgPMeta?.name ?? "shop-no-sidebar___ar",
    path: shop_45no_45sidebarkdxCYx1BgPMeta?.path ?? "/ar/shop-no-sidebar",
    meta: shop_45no_45sidebarkdxCYx1BgPMeta || {},
    alias: shop_45no_45sidebarkdxCYx1BgPMeta?.alias || [],
    redirect: shop_45no_45sidebarkdxCYx1BgPMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/shop-no-sidebar.vue").then(m => m.default || m)
  },
  {
    name: shop_45right_45sidebarSDRHJc2hkGMeta?.name ?? "shop-right-sidebar___en",
    path: shop_45right_45sidebarSDRHJc2hkGMeta?.path ?? "/en/shop-right-sidebar",
    meta: shop_45right_45sidebarSDRHJc2hkGMeta || {},
    alias: shop_45right_45sidebarSDRHJc2hkGMeta?.alias || [],
    redirect: shop_45right_45sidebarSDRHJc2hkGMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/shop-right-sidebar.vue").then(m => m.default || m)
  },
  {
    name: shop_45right_45sidebarSDRHJc2hkGMeta?.name ?? "shop-right-sidebar___ar",
    path: shop_45right_45sidebarSDRHJc2hkGMeta?.path ?? "/ar/shop-right-sidebar",
    meta: shop_45right_45sidebarSDRHJc2hkGMeta || {},
    alias: shop_45right_45sidebarSDRHJc2hkGMeta?.alias || [],
    redirect: shop_45right_45sidebarSDRHJc2hkGMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/shop-right-sidebar.vue").then(m => m.default || m)
  },
  {
    name: shopZlsy0s5g7OMeta?.name ?? "shop___en",
    path: shopZlsy0s5g7OMeta?.path ?? "/en/shop",
    meta: shopZlsy0s5g7OMeta || {},
    alias: shopZlsy0s5g7OMeta?.alias || [],
    redirect: shopZlsy0s5g7OMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/shop.vue").then(m => m.default || m)
  },
  {
    name: shopZlsy0s5g7OMeta?.name ?? "shop___ar",
    path: shopZlsy0s5g7OMeta?.path ?? "/ar/shop",
    meta: shopZlsy0s5g7OMeta || {},
    alias: shopZlsy0s5g7OMeta?.alias || [],
    redirect: shopZlsy0s5g7OMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/shop.vue").then(m => m.default || m)
  },
  {
    name: success_45login8SyYaxG1nVMeta?.name ?? "success-login___en",
    path: success_45login8SyYaxG1nVMeta?.path ?? "/en/success-login",
    meta: success_45login8SyYaxG1nVMeta || {},
    alias: success_45login8SyYaxG1nVMeta?.alias || [],
    redirect: success_45login8SyYaxG1nVMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/success-login.vue").then(m => m.default || m)
  },
  {
    name: success_45login8SyYaxG1nVMeta?.name ?? "success-login___ar",
    path: success_45login8SyYaxG1nVMeta?.path ?? "/ar/success-login",
    meta: success_45login8SyYaxG1nVMeta || {},
    alias: success_45login8SyYaxG1nVMeta?.alias || [],
    redirect: success_45login8SyYaxG1nVMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/success-login.vue").then(m => m.default || m)
  },
  {
    name: wishlistvwRdEjrh6AMeta?.name ?? "wishlist___en",
    path: wishlistvwRdEjrh6AMeta?.path ?? "/en/wishlist",
    meta: wishlistvwRdEjrh6AMeta || {},
    alias: wishlistvwRdEjrh6AMeta?.alias || [],
    redirect: wishlistvwRdEjrh6AMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlistvwRdEjrh6AMeta?.name ?? "wishlist___ar",
    path: wishlistvwRdEjrh6AMeta?.path ?? "/ar/wishlist",
    meta: wishlistvwRdEjrh6AMeta || {},
    alias: wishlistvwRdEjrh6AMeta?.alias || [],
    redirect: wishlistvwRdEjrh6AMeta?.redirect || undefined,
    component: () => import("/home/urusglobal/swift-ecommerce.urusglobal.com/pages/wishlist.vue").then(m => m.default || m)
  }
]