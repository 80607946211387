import { ref, onMounted } from "vue";
import { type IProduct } from "@/types/product-type";
import { defineStore, storeToRefs } from 'pinia';
import { toast } from "vue3-toastify";
import { useAuthStore } from "./useAuthStore";
import type { IWishlist } from "@/types/wishlist-type";
import { useT } from "../helpers/useT";

export const useWishlistStore = defineStore("wishlist_product", () => {
  const { isAuth } = storeToRefs(useAuthStore());
  let wishlists = ref<IProduct[]>([]);
  let authenticatedWishlist = ref<IWishlist[]>([]);
  const wishlistCount = computed(() => {
    return wishlists.value.length;
  });
  const { t } = useI18n();
  // add_wishlist_product
  const removeWishlist = (payload: IProduct) => {
    wishlists.value = wishlists.value.filter((p) => p.id !== payload.id);
    toast.error(useT('removed_from_wishlist', { title: payload.name }));
    localStorage.setItem("wishlist_products", JSON.stringify(wishlists.value));
    if (isAuth.value) {
      let wishlistID = authenticatedWishlist.value.find((p) => p.product.id === payload.id)?.id;
      deleteWishlist(wishlistID ?? 0);
    }
  };
  const add_wishlist_product = (payload: IProduct) => {
    const isAdded = wishlists.value.findIndex((p) => p.id === payload.id);
    if (isAdded !== -1) {
      removeWishlist(payload);
    } else {
      wishlists.value.push(payload);
      if (isAuth.value)
        addWishlist(payload.id);
      toast.success(useT('added_to_wishlist', { title: payload.name }));
    }
    localStorage.setItem("wishlist_products", JSON.stringify(wishlists.value));
  };
  // removeWishlist


  // cart product initialize
  const initializeWishlistProducts = () => {
    const wishlistData = localStorage.getItem("wishlist_products");
    if (wishlistData) {
      wishlists.value = JSON.parse(wishlistData);
    }
  };
  const addWishlist = async (product_id: number, token: string = "") => {
    if (token.length == 0)
      token = useCookie("token").value;
    await fetch("/favorites", {
      headers: {
        Authorization: "Bearer " + token
      },
      method: "POST",
      body: JSON.stringify({ product_id }),
    }).then((response) => {
      authenticatedWishlist.value.push(response.data);
    })
  }
  const deleteWishlist = async (wishlistID: number) => {
    if (wishlistID === 0) return;
    await fetch(`/favorites/${wishlistID}`, {
      method: "DELETE",
    }).then((response) => {
      if (response.success)
        authenticatedWishlist.value = authenticatedWishlist.value.filter((p) => p.id !== wishlistID)
    });
  }
  const getWishlistItems = async (token: string = "") => {
    if (token.length == 0)
      token = useCookie("token").value;
    await fetch("/favorites", {
      headers: {
        Authorization: "Bearer " + token
      }
    }).then((res) => {
      // console.log(res);
      //compare between the two arrays

      authenticatedWishlist.value = res.data;
      let notFoundInWishlist = authenticatedWishlist.value.filter((p) => !wishlists.value.find((w) => w.id === p.product.id));
      let notFoundInAuth = wishlists.value.filter((w) => !authenticatedWishlist.value.find((p) => w.id === p.product.id));
      notFoundInWishlist.forEach((p) => {
        wishlists.value.push(p.product);
      });
      notFoundInAuth.forEach(async (p) => {
        await addWishlist(p.id, token);
      });

    });
  }
  // mounted to update cart products
  onMounted(() => {
    initializeWishlistProducts();
    if (isAuth.value)
      getWishlistItems();
  });
  return {
    add_wishlist_product,
    removeWishlist,
    wishlists,
    getWishlistItems,
    wishlistCount
  };
});
