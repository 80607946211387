<template>
  <div class="tp-product-details-wrapper has-sticky">
    <div class="tp-product-details-category" v-if="product.categories">
      <span>{{ product?.categories[0]?.name ?? "" }}</span>
    </div>
    <h3 class="tp-product-details-title">{{ product.name }}</h3>
    <!-- inventory details -->
    <div class="tp-product-details-inventory d-flex align-items-center mb-10">
      <div class="tp-product-details-stock mb-10">
        <span>{{
          currentVariation.current_stock == 0
            ? $t("Out Of Stock")
            : $t("In Stock")
        }}</span>
      </div>
      <div
        class="tp-product-details-rating-wrapper d-flex align-items-center mb-10"
      >
        <div class="tp-product-details-rating">
          <span v-for="rating in product.rating ?? 0" :key="rating"
            ><i class="fa-solid fa-star"></i
          ></span>
        </div>
        <div class="tp-product-details-reviews">
          <span>({{ product.reviews_count }} {{ $t("Reviews") }})</span>
        </div>
      </div>
    </div>
    <p>
      {{
        textMore
          ? product.description
          : `${product.description.substring(0, 100)}...`
      }}
      <span @click="textMore = !textMore">{{
        textMore ? "See less" : "See more"
      }}</span>
    </p>

    <!-- price -->
    <div class="tp-product-details-price-wrapper mb-20">
      <div v-if="product.old_price != ''">
        <span class="tp-product-details-price old-price">{{
          formatPrice(product.old_price)
        }}</span>
        <span class="tp-product-details-price new-price">
          {{ currentVariation.price?.toFixed(2) }}
          <!-- ${{(Number(product.price) - (Number(product.price) * Number(product.discount)) / 100).toFixed(2)}} -->
        </span>
      </div>
      <span v-else class="tp-product-details-price">{{
        formatPrice(currentVariation.price?.toFixed(2))
      }}</span>
    </div>

    <!-- variations -->
    <div class="tp-product-details-variation">
      <div
        class="tp-product-details-variation-item"
        v-for="(attribute, index) in product.attributes"
        :key="index"
      >
        <h4 class="tp-product-details-variation-title">
          {{ attribute.name }} :
        </h4>
        <div class="tp-product-details-variation-list">
          <!-- @click="productStore.handleImageActive(item.img)" -->
          <!-- item.img === productStore.activeImg ? 'active' : '', -->
          <button
            v-for="(value, i) in attribute.values"
            :key="i"
            type="button"
            :class="[
              'color',
              'tp-color-variation-btn',
              isSelectedAttribute(value.id) ? 'active' : '',
              index == 0 || isAvailableAttribute(attribute.id, value.id)
                ? ''
                : 'not-available',
            ]"
            @click="selectAttribute(attribute.id, value.id)"
            style="margin-right: 5px"
          >
            {{ value.name }}
            <span class="tp-color-variation-tootltip">
              {{ value.name }}
            </span>
          </button>
        </div>
      </div>
    </div>

    <!-- product countdown start -->
    <!-- <div v-if="product.offerDate?.endDate">
    <product-details-countdown :product="product"/>
  </div> -->
    <!-- product countdown end -->

    <!-- actions -->
    <div class="tp-product-details-action-wrapper">
      <h3 class="tp-product-details-action-title">{{ $t("Quantity") }}</h3>
      <div
        class="tp-product-details-action-item-wrapper d-flex align-items-center"
      >
        <div class="tp-product-details-quantity">
          <div class="tp-product-quantity mb-15 mr-15">
            <span
              class="tp-cart-minus"
              @click="decrementQuantity(product.min_purchase_qty)"
            >
              <svg-minus />
            </span>
            <input
              class="tp-cart-input"
              type="text"
              :value="currentQuantity"
              disabled
            />
            <span
              class="tp-cart-plus"
              @click="
                incrementQuantity(
                  currentVariation.current_stock,
                  product.max_purchase_qty
                )
              "
            >
              <svg-plus-sm />
            </span>
          </div>
        </div>
        <div
          class="tp-product-details-add-to-cart mb-15 w-100 d-flex justify-content-between"
        >
          <button
            @click="addToCart"
            class="tp-product-details-add-to-cart-btn w-80"
            :disabled="currentVariation.current_stock == 0"
          >
            {{ $t("Add To Cart") }}
          </button>
          <button
            @click="wishlistStore.add_wishlist_product(product)"
            type="button"
            :class="`tp-product-action-btn tp-product-add-to-wishlist-btn ${
              isItemInWishlist(product) ? 'active' : ''
            }`"
          >
            <svg-wishlist />
            <span class="tp-product-tooltip">
              {{
                isItemInWishlist(item)
                  ? "Remove From Wishlist"
                  : "Add To Wishlist"
              }}
            </span>
          </button>
        </div>
      </div>
      <button
        @click="buyNow"
        class="tp-product-details-add-to-cart-btn btn btn-primary w-100"
        style="background-color: #e84e1c"
        :disabled="currentVariation.current_stock == 0"
      >
        {{ $t("Buy Now") }}
      </button>
    </div>
    <div class="tp-product-details-action-sm">
      <!-- <button
        @click="compareStore.add_compare_product(product)"
        type="button"
        class="tp-product-details-action-sm-btn"
      >
        <svg-compare-3 />
        {{ $t("Compare") }}
      </button> -->
      <button
        @click="wishlistStore.add_wishlist_product()"
        type="button"
        class="tp-product-details-action-sm-btn p-4"
      >
        <svg-wishlist-3 />
        {{ $t("Add Wishlist") }}
      </button>
    </div>

    <div v-if="isShowBottom">
      <div class="tp-product-details-query">
        <div class="tp-product-details-query-item d-flex align-items-center">
          <span>SKU: </span>
          <p>{{ product.sku }}</p>
        </div>
        <div class="tp-product-details-query-item d-flex align-items-center">
          <span>{{ $t("Category") }}: </span>
          <p>
            <nuxt-link-locale :href="`/category/${product.categories[0].slug}`">
              {{ product.categories[0].name }}</nuxt-link-locale
            >
          </p>
        </div>
        <div class="tp-product-details-query-item d-flex align-items-center">
          <span>{{ $t("Brand") }}: </span>
          <p>
            <nuxt-link-locale :href="`/brand/${product.brand_details.slug}`">{{
              product.brand_details.name
            }}</nuxt-link-locale>
          </p>
        </div>
        <!-- <div class="tp-product-details-query-item d-flex align-items-center">
          <span>Tag: </span>
          <p>Android</p>
        </div> -->
      </div>
      <ClientOnly>
        <div class="tp-product-details-social">
          <span>{{ $t("Share") }}: </span>
          <a
            :href="`https://www.facebook.com/sharer/sharer.php?u=${productUrl}`"
            target="_blank"
            ><i class="fa-brands fa-facebook-f"></i
          ></a>
          <a
            :href="`https://twitter.com/intent/tweet?url=${productUrl}`"
            target="_blank"
            ><i class="fa-brands fa-twitter"></i
          ></a>
          <a
            :href="`https://www.linkedin.com/sharing/share-offsite/?url=${productUrl}`"
            target="_blank"
            ><i class="fa-brands fa-linkedin-in"></i
          ></a>
          <!-- <a href="#"><i class="fa-brands fa-vimeo-v"></i></a> -->
        </div>
      </ClientOnly>
      <!-- <div class="tp-product-details-msg mb-15">
        <ul>
          <li>30 days easy returns</li>
          <li>Order yours before 2.30pm for same day dispatch</li>
        </ul>
      </div> -->
      <div
        class="tp-product-details-payment d-flex align-items-center flex-wrap justify-content-between"
      >
        <p>
          {{ $t("Guaranteed safe") }} <br />
          & {{ $t("secure checkout") }}
        </p>
        <img src="/img/product/icons/payment-option.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useProductStore } from "@/pinia/useProductStore";
import { type IProduct } from "@/types/product-type";
import { useCartStore } from "@/pinia/useCartStore";
import { useCompareStore } from "@/pinia/useCompareStore";
import { useWishlistStore } from "@/pinia/useWishlistStore";
import { useCurrencyStore } from "../../pinia/useCurrencyStore";
import { storeToRefs } from "pinia";
import { toast } from "vue3-toastify";
import { type CartProduct } from "../../types/cart-type";

// store
const compareStore = useCompareStore();
const wishlistStore = useWishlistStore();
const productStore = useProductStore();
const cartStore = useCartStore();
// props
const props = withDefaults(
  defineProps<{ product: IProduct; isShowBottom?: boolean }>(),
  {
    isShowBottom: true,
  }
);
const { currentQuantity } = storeToRefs(useCartStore());
watch(
  () => props.product,
  () => {
    currentQuantity.value = props.product.min_purchase_qty;
  }
);
const { incrementQuantity, decrementQuantity } = useCartStore();
const quantity = ref<number>(props.product.min_purchase_qty ?? 1);
let textMore = ref<boolean>(false);
const { formatPrice } = useCurrencyStore();

const { selectedAttributes, formattedAvailableAttributes, currentVariation } =
  storeToRefs(useProductStore());
const { formatAvailableAttributes } = useProductStore();
const isSelectedAttribute = (attributeID: number) => {
  return selectedAttributes.value.find((id) => attributeID == id);
};

const isAvailableAttribute = (attributeID: number, valueID: number) => {
  if (!formattedAvailableAttributes.value[attributeID]) return false;
  return formattedAvailableAttributes.value[attributeID].find(
    (id) => valueID == id
  );
};
function isItemInWishlist() {
  return wishlistStore.wishlists.some((prd) => prd.id === props.product.id);
}
const selectAttribute = (attributeID: number, valueID: number) => {
  //first find the variation with this attribute and value ids
  //then set the selected attribute values to the selected attribute values
  const variation = props.product.variations.find((variation) =>
    variation.attributes.find(
      (attr) => attr.id == attributeID && attr.values[0].id == valueID
    )
  );
  if (variation) {
    selectedAttributes.value[attributeID] = [valueID];
    let attributes = variation.attributes;
    // for (let i = 0; i < attributes.length; i++) {
    //   selectedAttributes.value[attributes[i].id] = [attributes[i].values[0].id];
    // }
    formattedAvailableAttributes.value = formatAvailableAttributes(
      props.product.variations
    );
    currentVariation.value = variation;
  }
};
// const incrementQuantity = () => {
//   if (quantity.value + 1 > currentVariation.current_stock) {
//     toast.error("You can't add more than the available stock");
//     return;
//   }
//   if (quantity.value + 1 > props.product.max_purchase_qty) {
//     toast.error("You can't add more than the maximum purchase quantity");
//     return;
//   }
//   quantity.value++;
// };
// const decrementQuantity = () => {
//   if (quantity.value <= 1) return;
//   if (quantity.value - 1 < props.product.min_purchase_qty) {
//     toast.error("You can't add less than the minimum purchase quantity");
//     return;
//   }
//   quantity.value--;
// };
const addToCart = () => {
  let newProduct = {
    quantity: currentQuantity.value,
    product: props.product,
    variation: currentVariation.value,
    id: currentVariation.value.id,
  } as CartProduct;
  cartStore.addCartProduct(newProduct);
};
const buyNow = async () => {
  let newProduct = {
    quantity: currentQuantity.value,
    product: props.product,
    variation: currentVariation.value,
    id: currentVariation.value.id,
  } as CartProduct;
  const localePath = useLocalePath();

  await cartStore.addCartProduct(newProduct).then((res) => {
    navigateTo(localePath("/checkout"));
  });
};
const productUrl = computed(() => {
  if (process.client) return location.href;
  return;
  // return encodeURIComponent(document.URL);
});
</script>
<style>
.not-available {
  background-color: #f8d7da;
  color: #721c24;
  border-color: #f5c6cb;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 5px;
}
.tp-product-details-variation-list button.active {
  background-color: #e84f1cce;
  color: #fff;
}
.w-80 {
  width: 85%;
}
.tp-product-action-btn {
  height: auto;
  width: 50px;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
</style>
