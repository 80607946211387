import { useCartStore } from './useCartStore';
import { ref, watch } from "vue";
import { defineStore } from "pinia";
import { type IProduct } from '@/types/product-type';
import { useProductStore } from './useProductStore';
import product_data from '@/data/product-data';
import type { ILanguage } from '@/types/languague-type';
import { useHomeStore } from './useHomeStore';

export const useUtilityStore = defineStore("utility", () => {
  const route = useRoute();
  const productStore = useProductStore();
  const cartStore = useCartStore();
  const currentLanguage = useCookie<ILanguage>("language", {
    default: () => {
      return {
        name: "English",
        code: "en"
      }
    }
  });
  let openSearchBar = ref<boolean>(false);
  let openMobileMenus = ref<boolean>(false);
  // product modal
  let modalId = ref<string | null>('product-modal-641e887d05f9ee1717e1348a');
  let product = ref<IProduct | null>(null);
  // video modal
  const videoUrl = ref<string>('https://www.youtube.com/embed/EW4ZYb3mCZk')
  const isVideoOpen: Ref<boolean> = ref(false);
  let iframeElement: HTMLIFrameElement | null = null;

  // handle image active
  const handleOpenSearchBar = () => {
    openSearchBar.value = !openSearchBar.value;
  };

  // handle image active
  const handleOpenMobileMenu = () => {
    openMobileMenus.value = !openMobileMenus.value;
  };

  // modal video play
  const playVideo = (videoId: string) => {
    const videoOverlay = document.querySelector("#video-overlay");
    videoUrl.value = `https://www.youtube.com/embed/${videoId}`
    if (!iframeElement) {
      iframeElement = document.createElement("iframe");
      iframeElement.setAttribute("src", videoUrl.value);
      iframeElement.style.width = "60%";
      iframeElement.style.height = "80%";
    }

    isVideoOpen.value = true;
    videoOverlay?.classList.add("open");
    videoOverlay?.appendChild(iframeElement);
  };
  // close modal video
  const closeVideo = () => {
    const videoOverlay = document.querySelector("#video-overlay.open");

    if (iframeElement) {
      iframeElement.remove();
      iframeElement = null;
    }

    isVideoOpen.value = false;
    videoOverlay?.classList.remove("open");
  };
  const { getProduct } = useProductStore();
  // handle Open Modal
  const handleOpenModal = async (id: string, item: IProduct) => {
    product.value = null;
    modalId.value = id;
    product.value = await getProduct(item.slug)
    // product.value = item;
    productStore.handleImageActive(item.main_img?.full_url)

  };
  const handleSelectAttributes = (attributeID: number, valueID: number) => {

  }
  const removeBackdrop = () => {
    const modalBackdrop = document.querySelector('.modal-backdrop');
    if (modalBackdrop) {
      modalBackdrop.remove();
      document.body.classList.remove('modal-open');
      document.body.removeAttribute('style');
    }
  };
  const { getHomeData } = useHomeStore();
  // const { locale, setLocale } = useI18n();
  const setLanguage = (language: ILanguage) => {
    currentLanguage.value = language;
    useNuxtApp().$i18n.setLocale(language.code);
    let direction = language.code == "ar" ? "rtl" : "ltr";
    useHead({
      htmlAttrs: { dir: direction, lang: language.code },
    });
    // await getHomeData();

    //allow rtl when it selected ar
  };
  const initializeLanguage = async () => {
    // console.log(useNuxtApp.$i18n.locale);
    // if (!currentLanguage.value) {
    //   setLanguage({
    //     name: "English",
    //     code: "en"
    //   });
    // } else {
    //   setLanguage(currentLanguage.value);
    // }

  }
  watch(() => route.path, () => {
    openSearchBar.value = false;
    openMobileMenus.value = false;
  })
  const handleQuery = (query: string, newKey: string, newValue: string | number, initialQuery: object | null = null) => {

    const route = useRoute();
    let splittedQuery = [];
    if (initialQuery == null)
      initialQuery = route.query;
    //check is the key is found before
    let isAdded = false;
    for (const key in initialQuery) {
      if (newKey == key) {
        splittedQuery.push(`${key}=${newValue}`);
        isAdded = true;
      } else
        splittedQuery.push(`${key}=${initialQuery[key]}`);
    }
    if (!isAdded)
      splittedQuery.push(query);
    return splittedQuery.join("&");
  }

  return {
    handleOpenSearchBar,
    openSearchBar,
    handleOpenModal,
    modalId,
    product,
    openMobileMenus,
    handleOpenMobileMenu,
    playVideo,
    isVideoOpen,
    iframeElement,
    closeVideo,
    removeBackdrop,
    currentLanguage,
    handleSelectAttributes,
    handleQuery,
    setLanguage,
    initializeLanguage
  };
});
