import type { ICurrency } from "@/types/currency-type";

export const useCurrencyStore = defineStore("currency", () => {
    const currentCurrency = useCookie<ICurrency | undefined>('currency', {
        default: () => {
            return {
                id: 3,
                name: "KWD",
                code: "KWD",
                symbol: "kwd",
                exchange_rate: "1.00000000",
                active: true,
                default: null,
                trans: null,
            }
        }
    });
    const currencies = ref<ICurrency[]>([]);
    const getAllCurrencies = async () => {
        await fetch("/currencies").then((res) => {
            currencies.value = res.data;
            currentCurrency.value = currencies.value[0];
        });
    }
    const formatPrice = (price: string) => {
        return (currentCurrency.value?.symbol ?? "") + price
    }

    return {
        currentCurrency,
        currencies,
        getAllCurrencies,
        formatPrice
    };
});