import { defineStore } from "pinia";
import { ref, computed, watch } from "vue";
import product_data from "@/data/product-data";
import { max } from "@vee-validate/rules";
import { useLoader } from "./useLoader";
export const useProductFilterStore = defineStore("product_filter", () => {
  const route = useRoute();
  const router = useRouter();
  let selectVal = ref<string>("");
  const selectedCategory = ref<Object | null>(null);
  const selectedBrand = ref<Object | null>(null);
  const handleSelectFilter = (e: { value: string; text: string }) => {
    selectVal.value = e.value;
    getProducts();
  }
  const filteredProducts = ref({
    data: [],
    "meta": {
      "total": 0,
      "current_page": 1,
      "per_page": 9,
      "last_page": 1,

    }
  });
  const maxProductPrice = ref(0);
  const minProductPrice = ref(0)
  let priceValues = ref([minProductPrice.value, maxProductPrice.value]);

  const handlePriceChange = (value: number[]) => {
    priceValues.value = value;
  };

  const handleResetFilter = () => {
    priceValues.value = [minProductPrice.value, maxProductPrice.value];
  };

  const getProducts = async (query = {}) => {

    const { stopLoading } = useLoader();
    let queryString = [];
    if (Object.keys(query).length == 0) {
      query = route.query;
    }


    if (query.minPrice && query.maxPrice) {
      queryString.push(`price_from=${query.minPrice}`);
      queryString.push(`price_to=${query.maxPrice}`);
    }
    if (query.status) {
      if (query.status === "on-sale") {
        queryString.push("sale=1")
      } else if (query.status === "in-stock") {
        queryString.push("stock=1")
      }
    }
    if (query.category) {
      queryString.push(`category_id=${query.category}`);
    }
    else if (selectedCategory.value) {
      queryString.push(`category_id=${selectedCategory.value.id}`);
    }
    // else if (query.subCategory) {
    //   return product_data.filter(
    //     (p) =>
    //       p.children.toLowerCase().replace("&", "").split(" ").join("-") ===
    //       query.subCategory
    //   );
    // }
    if (query.brand) {
      queryString.push(`brand_id=${query.brand}`);
    } else if (selectedBrand.value) {
      queryString.push(`brand_id=${selectedBrand.value.id}`);
    }
    if (query.searchText) {
      queryString.push(`search=${query.searchText}`);
    }
    if (query.page) {
      queryString.push(`page=${query.page}`)
    }

    if (selectVal.value) {
      if (selectVal.value === "default-sorting") {
        queryString.push(`order_by=`);
        queryString.push(`order_dir=`);
      } else if (selectVal.value === "low-to-hight") {
        queryString.push(`order_by=price`);
        queryString.push(`order_dir=asc`);
      } else if (selectVal.value === "high-to-low") {
        queryString.push(`order_by=price`);
        queryString.push(`order_dir=desc`);
      } else if (selectVal.value === "new-added") {
        queryString.push(`order_by=id`);
        queryString.push(`order_dir=desc`);
      } else if (selectVal.value === "on-sale") {
        queryString.push(`order_by=applied_discount_id`);
        queryString.push(`order_dir=desc`);
      }
    }
    // } else {
    //   return product_data;
    // }
    queryString.push("per_page=9");
    let formattedQueryString = queryString.join("&");
    await fetch(`/products?${formattedQueryString}`, {
      method: "GET",

    }).then((res) => {
      //stopLoading();
      filteredProducts.value = res;
      let isDefault = maxProductPrice.value == 0 && minProductPrice.value == 0;
      if (maxProductPrice.value == 0)
        maxProductPrice.value = res.meta.max_price ?? 0;
      if (minProductPrice.value == 0)
        minProductPrice.value = res.meta.min_price ?? 0;
      if (isDefault) {
        handlePriceChange([minProductPrice.value, maxProductPrice.value])
      }
    })
  }
  // filteredProducts


  // filteredProducts
  const searchFilteredItems = computed(() => {
    let filteredProducts = [...product_data];

    if (route.query.searchText && !route.query.productType) {
      filteredProducts = filteredProducts.filter((prd) =>
        prd.title.toLowerCase().includes(route.query.searchText.toLowerCase())
      );
    }
    if (!route.query.searchText && route.query.productType) {
      filteredProducts = filteredProducts.filter(
        (prd) => prd.productType.toLowerCase() === route.query.productType.toLowerCase()
      );
    }
    if (route.query.searchText && route.query.productType) {
      filteredProducts = filteredProducts.filter(
        (prd) => prd.productType.toLowerCase() === route.query.productType.toLowerCase()
      ).filter(p => p.title.toLowerCase().includes(route.query.searchText.toLowerCase()));
    }
    switch (selectVal.value) {
      case "default-sorting":
        break;
      case "low-to-high":
        filteredProducts = filteredProducts.slice().sort((a, b) => Number(a.price) - Number(b.price));
        break;
      case "high-to-low":
        filteredProducts = filteredProducts.slice().sort((a, b) => Number(b.price) - Number(a.price));
        break;
      case "new-added":
        filteredProducts = filteredProducts.slice(-6);
        break;
      case "on-sale":
        filteredProducts = filteredProducts.filter((p) => p.discount > 0);
        break;
      default:
    }
    return filteredProducts;
  });


  watch(
    () => route.query || route.path,
    () => { }
  );
  return {
    maxProductPrice,
    minProductPrice,
    priceValues,
    handleSelectFilter,
    filteredProducts,
    handlePriceChange,
    handleResetFilter,
    selectVal,
    searchFilteredItems,
    getProducts,
    selectedCategory,
    selectedBrand,
  };
});
