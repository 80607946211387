import type { InstallmentPackage } from '@/types/installmanet-package';
import { type PaymentMethod } from '../types/payment-method-type';
import { useCartStore } from './useCartStore';
import { storeToRefs } from 'pinia';
import type { CheckoutData, ShippingMethods } from '@/types/checkout-type';
import { toast } from 'vue3-toastify';
import type { NewAddress } from '@/types/address-type';
import type { NewUser } from '@/types/user-type';
import { useAuthStore } from './useAuthStore';
export const useCheckoutStore = defineStore("checkout", () => {
    const localePath = useLocalePath();
    const checkoutData = ref<CheckoutData>({
        sub_total: 0,
        total: 0,
        discount: 0,
        items: [],
        addresses: "",
        payment_methods: [],
        installment_packages: [],
        shipping_methods: [],
    });
    const clearCache = ref(false);
    const paymentMethods = ref<PaymentMethod[]>([]);
    const installmentPackages = ref<InstallmentPackage[]>([]);
    const shippingMethods = ref<ShippingMethods[]>([]);
    const selectedPaymentMethod = useCookie<PaymentMethod | null>("payment_method", { default: () => null });
    const selectedInstallmentPackage = useCookie<InstallmentPackage | null>("installment_package", { default: () => null });
    const selectedShippingMethod = useCookie<ShippingMethods | null>("shipping_method", { default: () => null });
    const selectedAddress = useCookie<number>("selected_address", { default: () => 0 });
    const isValidShippingData = ref<boolean>(false);
    const newAddress = ref<NewAddress>({
        country_id: 0,
        city_id: 0,
        town: "",
        address: "",
        phone: "",
        postal_code: "",
        type: 1
    });
    const newUser = ref<NewUser>({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
    });
    const promoCode = useCookie<string>('promo_code', { default: () => "" });
    const getCheckoutData = async (retrievedCartID = "") => {
        const { cartID, cart_products } = storeToRefs(useCartStore());
        retrievedCartID = retrievedCartID || cartID.value;
        if (!retrievedCartID) return;
        let link = `/checkout?cart=${retrievedCartID}`;
        if (promoCode.value) {
            link += `&promo_code=${promoCode.value}`;
        }
        // if (clearCache.value) {
        //     link += `&test=`;
        // }
        await fetch(link, { cache: "no-cache" })
            .then((res) => {
                checkoutData.value = res.data;
                cart_products.value = res.data.items;
                paymentMethods.value = checkoutData.value?.payment_methods;
                installmentPackages.value = checkoutData.value?.installment_packages;
                shippingMethods.value = checkoutData.value?.shipping_methods;
                if (promoCode.value) {
                    toast.success("Coupon Applied Successfully");
                }
                clearCache.value = !clearCache.value;
            }).catch((err) => {
                console.log(err.data.message == 'Cart is empty')
                if (err.data.message == 'Cart is empty') {
                    cart_products.value = [];
                    checkoutData.value = {
                        sub_total: 0,
                        total: 0,
                        discount: 0,
                        items: [],
                        addresses: "",
                        payment_methods: [],
                        installment_packages: [],
                        shipping_methods: [],
                    }
                    return;
                } else if (err.data.errors.cart) {
                    cartID.value = "";
                    return;
                }
                toast.error(err.data.message);
                promoCode.value = "";
            });
    }
    const placeOrder = async () => {
        const { isAuth, user, splittedName } = useAuthStore();
        const { cartID, cart_products } = storeToRefs(useCartStore());
        if (isAuth) {
            newUser.value = {
                email: user.value?.email,
                first_name: splittedName[0],
                last_name: splittedName[1],
                phone: user.value?.phone,
            };
        }
        let orderData = {
            ...newUser.value,
            promo_code: promoCode.value,
            cart: cartID.value,
            shipping_method_id: selectedShippingMethod.value?.id,
            address_id: selectedAddress.value,
            address: {
                ...newAddress.value,
                phone: newUser.value?.phone
            },
            payment: {
                method_id: selectedPaymentMethod.value?.id,
                installment_package_id: selectedInstallmentPackage.value?.id
            }
        };
        await fetch("/checkout", {
            method: "POST",
            body: JSON.stringify(orderData),
            cache: "no-cache"
        }).then((res) => {

            if (res.data.payment_link) {
                toast.success(res.message);
                window.location.href = res.data.payment_link.link;
                return;
            }
            clearCheckoutData();
            navigateTo(localePath(`/order/${res.data.payment_info.id}`));
        });

    }
    const clearCheckoutData = () => {
        const { cartID, cart_products } = storeToRefs(useCartStore());
        cartID.value = "";
        promoCode.value = "";
        cart_products.value = [];
        checkoutData.value = {
            sub_total: 0,
            total: 0,
            discount: 0,
            items: [],
            addresses: "",
            payment_methods: [],
            installment_packages: [],
            shipping_methods: [],
        };
    }
    return {
        checkoutData,
        paymentMethods,
        installmentPackages,
        shippingMethods,
        selectedPaymentMethod,
        selectedInstallmentPackage,
        selectedShippingMethod,
        promoCode,
        selectedAddress,
        newAddress,
        newUser,
        isValidShippingData,
        clearCheckoutData,
        getCheckoutData,
        placeOrder
    }
});