import type { PaymentMethod } from "@/types/payment-method-type";
import { useCurrencyStore } from "./useCurrencyStore";
import type { InstallmentPackage } from "@/types/installmanet-package";
import { useCheckoutStore } from "./useCheckoutStore";
import type { ContactInfo } from "@/types/contact-info";
import type { Page } from "@/types/page-type";
import { useCartStore } from "./useCartStore";
import { useUtilityStore } from "./useUtilityStore";
import { useT } from "../helpers/useT";
import { useAuthStore } from "./useAuthStore";

export const useHomeStore = defineStore("home", () => {

    const sliders = ref([
        { "id": 17, "link": "https://www.facebook.com/", "ordering_number": 2, "title": "The best tablet Collection 2023", "sub_title": "Starting at 50.00$", "text": "Exclusive offer -30% off this week", "button": "Shop now", "created_at": "2024-08-19T17:38:29.000000Z", "default_img": "https://swift.urusglobal.com/storage/27/slider-2.png", "default_img_thumb": "https://swift.urusglobal.com/storage/27/conversions/slider-2-thumb.jpg" },
        { "id": 17, "link": "https://www.facebook.com/", "ordering_number": 2, "title": "The best tablet Collection 2023", "sub_title": "Starting at 50.00$", "text": "Exclusive offer -30% off this week", "button": "Shop now", "created_at": "2024-08-19T17:38:29.000000Z", "default_img": "https://swift.urusglobal.com/storage/27/slider-2.png", "default_img_thumb": "https://swift.urusglobal.com/storage/27/conversions/slider-2-thumb.jpg" }
    ]);
    const banners = ref([]);
    const categories = ref([]);
    const collections = ref([]);
    const brands = ref([]);
    const countries = ref([]);
    const cities = ref([]);
    const contactInfo = ref<ContactInfo>();
    const paymentMethods = ref<PaymentMethod[]>([]);
    const installmentPackages = ref<InstallmentPackage[]>([]);
    // const formattedSearchCategories = useCookie<Object[]>('categories', { default: () => [] });
    const formattedSearchCategories = ref<Object[]>([]);
    const pages = ref<Page[]>();
    // const { t } = useI18n();
    const getHomeData = async () => {

        await fetch("/home").then((res) => {
            sliders.value = res.sliders;//problem with window
            banners.value = res.banner;//problem with window
            categories.value = res.categories;
            collections.value = res.collections;
            formattedSearchCategories.value = [
                {
                    value: "",
                    text: useT('Select Category'),
                }
            ];
            categories.value.forEach((category) => {
                formattedSearchCategories.value.push({
                    value: category.id,
                    text: category.name,
                })
            });
        });
    };
    const getCategories = async () => {
        await fetch("/categories").then((res) => {
            categories.value = res.data;

            formattedSearchCategories.value = [
                {
                    value: "",
                    text: useT('Select Category'),
                }
            ];
            categories.value.forEach((category) => {
                formattedSearchCategories.value.push({
                    value: category.id,
                    text: category.name,
                })
            });
        });
    }
    const getBrands = async () => {
        await fetch("/brands", { cache: "force-cache" }).then((res) => {
            brands.value = res.data;
        });
    }
    const getCountries = async () => {
        await fetch("/countries?per_page=100").then((res) => {
            countries.value = res.data;
        });
    }
    const getCities = async () => {
        await fetch("/cities?per_page=1000").then((res) => {
            cities.value = res.data;
        });
    }
    const getPaymentMethods = async () => {
        await fetch("/payment_methods").then((res) => {
            paymentMethods.value = res.data;
        });
    }
    const getInstallmentPackages = async () => {
        await fetch("/installment-packages").then((res) => {
            installmentPackages.value = res.data;
        });
    }
    const getContactInfo = async () => {

        await fetch("/contact-info/1", { cache: "force-cache" }).then((res) => {
            contactInfo.value = res.data;
        });
    };
    const getPages = async () => {
        await fetch('/page', { cache: "force-cache" }).then((res) => {
            pages.value = res.data;
        });
    }
    const showPage = async (slug: string) => {
        return await fetch(`/page/${slug}`);
    }
    const sendMessage = async (data: any) => {
        return await fetch("contact-form", {
            method: "POST",
            body: JSON.stringify(data),
        });
    }
    const subscribeNewsletters = async (email: string) => {
        return await fetch("newsletter/subscribe", {
            method: "POST",
            body: JSON.stringify({ email, name: "" }),
        });
    }


    return {
        getHomeData,
        getBrands,
        getCountries,
        getCities,
        getPaymentMethods,
        getInstallmentPackages,
        showPage,
        sendMessage,
        subscribeNewsletters,
        getContactInfo,
        getPages,
        getCategories,
        sliders,
        banners,
        categories,
        collections,
        brands,
        formattedSearchCategories,
        countries,
        cities,
        paymentMethods,
        installmentPackages,
        contactInfo,
        pages
    }
});