<template>
  <NuxtLayout>
    <NuxtPage />
    <modal-product />
  </NuxtLayout>
</template>

<script setup lang="ts">
import { useProductFilterStore } from "./pinia/useProductFilterStore";
import { useUtilityStore } from "./pinia/useUtilityStore";
import { useAuthStore } from "@/pinia/useAuthStore";
import { useCheckoutStore } from "@/pinia/useCheckoutStore";

const route = useRoute();
const prdFilterStore = useProductFilterStore();
const utilsStore = useUtilityStore();
watch(
  () => route.path,
  () => {
    prdFilterStore.$reset;
    prdFilterStore.handleResetFilter();
    utilsStore.removeBackdrop();
  }
);
const { getCheckoutData } = useCheckoutStore();
const { getProfile } = useAuthStore();
await Promise.all([getCheckoutData(), getProfile()]);
</script>
