import product_data from '@/data/product-data';
import { defineStore } from "pinia";
import { ref } from "vue";

export const useProductStore = defineStore("product", () => {

  let activeImg = ref<string>(product_data[0].img);
  let openFilterDropdown = ref<boolean>(false);
  let openFilterOffcanvas = ref<boolean>(false);
  const selectedAttributes = ref<{}>({});
  const formattedAvailableAttributes = ref<{}>({});
  const currentVariation = ref<{}>({});
  // handle image active
  const handleImageActive = (img: string) => {
    activeImg.value = img;
  };

  // handle image active
  const handleOpenFilterDropdown = () => {
    openFilterDropdown.value = !openFilterDropdown.value
  };

  // handle image active
  const handleOpenFilterOffcanvas = () => {
    openFilterOffcanvas.value = !openFilterOffcanvas.value
  };
  const formatAvailableAttributes = (variations: []) => {
    let availableAttributes = {};
    for (let index = 0; index < variations.length; index++) {
      let currentVariation = variations[index];
      for (let j = 0; j < currentVariation.attributes.length; j++) {
        //if all selected attributes are in the current variation
        let currentAttribute = currentVariation.attributes[j];
        let firstValueID = currentAttribute.values[0].id;
        if (availableAttributes[currentAttribute.id]) {
          if (!availableAttributes[currentAttribute.id].includes(firstValueID))
            availableAttributes[currentAttribute.id].push(firstValueID);
        } else {
          availableAttributes[currentAttribute.id] = [firstValueID];
        }

        // if (!selectedAttributes.value[currentAttribute.id].includes(firstValueID))
        //   continue;


      }
    }
    return availableAttributes;
  }
  const getProduct = async (id: string) => {
    return await fetch(`products/${id}`).then((res) => {
      selectedAttributes.value = [];
      let product = res.data;
      handleImageActive(product.main_img.full_url);
      let attributes = product.variations[0].attributes;
      currentVariation.value = product.variations[0];
      for (let index = 0; index < attributes.length; index++) {
        let currentAttribute = attributes[index];
        if (selectedAttributes.value[currentAttribute.id]) {
          selectedAttributes.value[currentAttribute.id].push(currentAttribute.values[0].id);
        } else {
          selectedAttributes.value[currentAttribute.id] = [currentAttribute.values[0].id];
        }
      }
      formattedAvailableAttributes.value = formatAvailableAttributes(product.variations);
      let categorizedReviews = product.reviews;
      let reviewsCount = 0;
      let reviewsAVG = 0;
      for (const key in categorizedReviews) {
        reviewsCount += categorizedReviews[key];
        reviewsAVG += parseInt(key.slice(-1)) * categorizedReviews[key];
      }
      product.reviews_count = reviewsCount;
      if (reviewsCount === 0) {
        reviewsCount = 1;
      }
      for (const key in categorizedReviews) {
        categorizedReviews[key] = (categorizedReviews[key] / (reviewsCount)) * 100;
      }

      product.avg_rating = (reviewsAVG / reviewsCount).toFixed(1);
      product.categorized_reviews = categorizedReviews;

      return product;
    }
    );
  };
  return {
    activeImg,
    openFilterDropdown,
    openFilterOffcanvas,
    selectedAttributes,
    formattedAvailableAttributes,
    currentVariation,
    handleImageActive,
    handleOpenFilterDropdown,
    handleOpenFilterOffcanvas,
    getProduct,
    formatAvailableAttributes,
  };
});
